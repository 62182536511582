import React from 'react';
import './style.scss';

const IndexAspects = props => {
	return (
		<div className="gst-intro-aspects">
			<div className="gst-intro-aspects-header">
				<h2>All you need to make Earth Observation work for you</h2>
			</div>
			<div className="gst-intro-aspects-content">
				<div className="gst-intro-aspect left">
					<h3>Understanding satellite data</h3>
					<p>
						Knowing Earth observation inside out, we select and fuse the
						fit-to-purpose data. To respond to big data challenges, we operate a
						scalable and cloud-agnostic production system for automated
						satellite data processing.
					</p>
				</div>
				<div className="gst-intro-aspect right">
					<h3>From data to information</h3>
					<p>
						Analysing complex data and problems require specific expertise and
						cutting-edge solutions. Empowered by data mining, geostatistics and
						machine learning, we provide information products with the best
						value for businesses.
					</p>
				</div>
				<div className="gst-intro-aspect left">
					<h3>Decision-ready insights</h3>
					<p>
						Bringing together domain knowledge, powerful analytics and
						interactive visualisation platforms, we deliver unique insights and
						data exploration to support better-informed decisions.
					</p>
				</div>
				<div className="gst-intro-aspect right">
					<h3>Web platforms and tools</h3>
					<p>
						Our web platforms and software tools provide customisable
						purpose-built solutions. Open source and industry standards
						facilitate embedding geospatial dimension into our clients’
						decision-making mechanisms.
					</p>
				</div>
			</div>
		</div>
	);
};

IndexAspects.propTypes = {};

export default IndexAspects;
