import React from 'react';
import PropTypes from 'prop-types';
import {StaticQuery, graphql} from 'gatsby';
import {GatsbyImage} from 'gatsby-plugin-image';

const Image = ({imagePath}) => (
	<StaticQuery
		query={graphql`
			{
				images: allFile(
					filter: {
						extension: {regex: "/(jpg)|(jpeg)|(png)/"}
						relativeDirectory: {eq: "pages/homepage/business_partners"}
					}
				) {
					edges {
						node {
							relativePath
							name
							childImageSharp {
								gatsbyImageData(width: 300, layout: CONSTRAINED)
							}
						}
					}
				}
			}
		`}
		render={data => {
			const image = data.images.edges.find(n => {
				return imagePath.includes(n.node.relativePath);
			});

			if (image) {
				return (
					<GatsbyImage image={image.node.childImageSharp.gatsbyImageData} />
				);
			} else {
				return null;
			}
		}}
	/>
);

const Partner = ({id, url, name, imagePath}) => {
	return (
		<a
			id={id}
			className="gst-business-partner name"
			href={url ? url : ''}
			target="_blank"
			rel="noreferrer noopener"
		>
			{name}
			{/*<Image imagePath={imagePath} />*/}
		</a>
	);
};

Partner.propTypes = {
	imagePath: PropTypes.string.isRequired,
};

export default Partner;
