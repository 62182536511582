import React from 'react';
import {useStaticQuery, graphql, Link} from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Content from '../components/Content';
import Hero from '../components/Hero';
import WireBlock from '../components/wire/WireBlock';

import Block from '../components/Block';

import Vectors from '../images/pages/homepage/hero_vector/vectors.js';

import IntroHeroCaptions from '../components/IntroHeroCaptions';
import IntroHeroCaption from '../components/IntroHeroCaptions/IntroHeroCaption';
import IndexAspects from '../components/IndexAspects';
import BusinessPartners from '../components/BusinessPartners';

const lang = 'cs';

const IndexPage = () => {
	const data = useStaticQuery(graphql`
		{
			heroBackgroundImage: file(
				relativePath: {eq: "pages/homepage/index10.jpg"}
			) {
				childImageSharp {
					gatsbyImageData(layout: FULL_WIDTH)
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO />
			<Hero
				backgroundImageFluid={
					data.heroBackgroundImage.childImageSharp.gatsbyImageData
				}
				className="gst-index-hero"
				coloredOverlay

				// Overlay={
				// 	<div className={'vector-overlays'}>
				// 		{Vectors}
				// 		<IntroHeroCaptions originalWidth={4961} originalHeight={3307}>
				// 			<IntroHeroCaption id="urban" right={55.82} top={53.6} xShift={20}>
				// 				<em>3 %</em> of urban growth in last decade
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="field-caption-1"
				// 				left={76.66}
				// 				top={54.2}
				// 				xShift={20}
				// 			>
				// 				Currently cultivated crop: <em>Wheat</em>
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="field-caption-4"
				// 				left={69.7}
				// 				top={49.1}
				// 				xShift={20}
				// 			>
				// 				<em>126 days</em> of growing season in 2020
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="track-caption-1"
				// 				right={42.6}
				// 				top={78.8}
				// 				xShift={20}
				// 			>
				// 				<em>23 mm</em> terrain subsidence last month
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="track-caption-1-alt"
				// 				right={42.6}
				// 				top={49.8}
				// 				xShift={20}
				// 			>
				// 				<em>23 mm</em> terrain subsidence last month
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="field-caption-3"
				// 				top={55.8}
				// 				right={33.78}
				// 				xShift={20}
				// 			>
				// 				Area of <em>150 ha</em>
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="field-caption-2"
				// 				left={80.45}
				// 				top={60.2}
				// 				xShift={20}
				// 			>
				// 				Volume of water in leaves: <em>84 %</em>
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="track-caption-2"
				// 				left={65.9}
				// 				top={84.5}
				// 				xShift={20}
				// 			>
				// 				<em>5%</em> slope
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="land-use-caption"
				// 				right={76.32}
				// 				top={60.6}
				// 				xShift={20}
				// 			>
				// 				Land use: <em>Recreation Facilities</em>
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="forest-caption"
				// 				right={62.8}
				// 				top={65.95}
				// 				xShift={20}
				// 			>
				// 				<em>7 %</em> of deforestation from 2015
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="river-caption-1"
				// 				right={41.65}
				// 				top={68.8}
				// 				xShift={20}
				// 			>
				// 				<em>
				// 					12 m<sup>3</sup>&#47;s
				// 				</em>
				// 			</IntroHeroCaption>
				// 			<IntroHeroCaption
				// 				id="river-caption-2"
				// 				left={63.25}
				// 				top={76}
				// 				xShift={20}
				// 			>
				// 				<em>Unexpected</em> water occurrence in April 2021
				// 			</IntroHeroCaption>
				// 		</IntroHeroCaptions>
				// 	</div>
				// }
			>
				<h1>Actionable intelligence from Earth observation</h1>
				<p>
					With 30 years of experience, we transform complex remote sensing data
					into insights ready to serve your decision making.
				</p>
			</Hero>
			<Block light className="gst-index-block">
				<Content>
					<IndexAspects />
				</Content>
			</Block>
			<Block light gray className="gst-index-block">
				<Content>
					<BusinessPartners />
				</Content>
			</Block>
			<Block light className="gst-index-block">
				<Content>
					<h2>Learn more about our work</h2>
					<div className="gst-index-sections">
						<div className="gst-index-sections-card">
							<Link to={'/work#sectors'}>
								<h3>Sectors</h3>
								<span>See solutions tailored for your industry</span>
							</Link>
						</div>
						<div className="gst-index-sections-card">
							<Link to={'/work#case-studies'}>
								<h3>Case studies</h3>
								<span>Get inspired by our projects</span>
							</Link>
						</div>
						<div className="gst-index-sections-card">
							<Link to={'/work#services'}>
								<h3>Services</h3>
								<span>For specific needs</span>
							</Link>
						</div>
					</div>
				</Content>
			</Block>
		</Layout>
	);
};

export default IndexPage;
