import React from 'react';
import {withResizeDetector} from 'react-resize-detector';
import './style.scss';

class IntroHeroCaptions extends React.PureComponent {
	render() {
		// TODO it works like background-size: cover
		const {width, height, originalWidth, originalHeight, children} = this.props;
		const originalRatio = originalWidth / originalHeight;

		let onePercentOfAdjustedOriginalWidth = null;
		let onePercentOfAdjustedOriginalHeight = null;
		let horizontalAdjustmentInPx = null;
		let verticalAdjustmentInPx = null;
		const labelHeightInPx = 22;

		if (width && height) {
			const currentRatio = width / height;

			if (currentRatio < originalRatio) {
				const adjustedOriginalWidth = height * originalRatio;
				onePercentOfAdjustedOriginalWidth = adjustedOriginalWidth / 100;
				horizontalAdjustmentInPx = (adjustedOriginalWidth - width) / 2;

				onePercentOfAdjustedOriginalHeight = height / 100;
				verticalAdjustmentInPx = labelHeightInPx;
			} else {
				const adjustedOriginalHeight = width / originalRatio;
				onePercentOfAdjustedOriginalHeight = adjustedOriginalHeight / 100;
				verticalAdjustmentInPx =
					(adjustedOriginalHeight - height) / 2 + labelHeightInPx;
			}
		}

		const childrenWithProps = React.Children.map(children, child => {
			let {left, right, top} = child.props;

			if (horizontalAdjustmentInPx) {
				if (left) {
					const newLeftInPx =
						left * onePercentOfAdjustedOriginalWidth - horizontalAdjustmentInPx;
					left = (newLeftInPx * 100) / width;
				} else {
					const newRightInPx =
						right * onePercentOfAdjustedOriginalWidth -
						horizontalAdjustmentInPx;
					right = (newRightInPx * 100) / width;
				}
			}

			if (verticalAdjustmentInPx) {
				const newTopInPx =
					top * onePercentOfAdjustedOriginalHeight - verticalAdjustmentInPx;
				top = (newTopInPx * 100) / height;
			}

			if (React.isValidElement(child)) {
				let xShiftInPx = child.props.xShift || 0;
				if (xShiftInPx) {
					if (left) {
						left = left - (xShiftInPx / width) * 100;
					} else {
						right = right - (xShiftInPx / width) * 100;
					}
				}

				return React.cloneElement(child, {
					left,
					right,
					top,
				});
			}
			return child;
		});

		return (
			<div className="gst-intro-hero-map-captions">{childrenWithProps}</div>
		);
	}
}

export default withResizeDetector(IntroHeroCaptions);
