import React from 'react';
import {getBusinessPartners} from '../../selectors/data';
import Partner from './Partner';
import './style.scss';

export default () => {
	const partners = getBusinessPartners();

	if (partners) {
		return (
			<div className="gst-intro-business-partners">
				<span>Trusted by</span>
				{partners.map(partner => (
					<Partner
						key={partner.key}
						id={'business_partner_' + partner.key}
						imagePath={partner.imagePath}
						url={partner.url}
						name={partner.name}
					/>
				))}
			</div>
		);
	} else {
		return null;
	}
};
