import React from 'react';
import './style.scss';

class IntroHeroCaption extends React.PureComponent {
	render() {
		const {children, left, top, bottom, right, id} = this.props;
		let style = {};
		if (left) {
			style.left = `${left}%`;
		} else {
			style.right = `${right}%`;
		}

		style.top = `${top}%`;

		return (
			<div id={id} className="gst-intro-hero-map-caption" style={style}>
				{children}
			</div>
		);
	}
}

export default IntroHeroCaption;
